<template>
  <div>
    <v-select
      style="width:300px"
      :items="caseItems"
      v-model="innerStatusCase"
      label="innerStatusCase"
      class="d-inline-block mb-n6"
      background-color="white"
      outlined
      return-object
      dense
      :placeholder="OtherConfig.defaultPlaceHolder"
    ></v-select>
    <v-select
      style="width:300px"
      :items="sueItems"
      v-model="innerStatusSue"
      label="innerStatusSue"
      class="d-inline-block mb-n6"
      background-color="white"
      outlined
      return-object
      dense
      :placeholder="OtherConfig.defaultPlaceHolder"
    ></v-select>

    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-center">No.</th>
          <th class="text-center">Action</th>

          <th class="text-center">Description</th>
          <!-- <th class="text-center">Description</th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item,index) in filterdItems"
          :key="index"
        >
          <td
            class="text-center"
            width="10"
          >{{index+1}}</td>
          <td
            class="py-2"
            width="40"
          >
            <v-btn
              small
              @click="item.func"
              class="primary"
            >Click</v-btn>
          </td>
          <td>{{item.name}}</td>

          <!-- <td></td> -->
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import {
  StatusCase,
  StatusSue,
  UserRole,
  OtherConfig,
} from "./../../js/constants";
import { getStatusSue, getStatusCase } from "./../../js/dropdown-service";
import * as fakeUpdateStatus from "./../../_services/fake-update-sue-status-service";
export default {
  props: {
    case: {
      type: Object,
    },
    sue: {
      type: Object,
    },
    implement: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerStatusCase: {
      get() {
        if (this.case) {
          this.statusCase = this.case;
        }
        return this.statusCase;
      },
      set(val) {
        this.statusCase = val;
        this.emitStatus();
      },
    },
    innerStatusSue: {
      get() {
        if (this.sue) {
          this.statusSue = this.sue;
        }
        return this.statusSue;
      },
      set(val) {
        this.statusSue = val;
        this.emitStatus();
      },
    },
    filterdItems() {
      if (this.implement) {
        let arr = [];
        this.items.forEach((item) => {
          if (this.hasPermission(item.permission) && item.condition()) {
            arr.push(item);
          }
        });

        return arr.filter((x) => x.alwaysShow);
      }
      return this.items;
    },
  },
  data() {
    return {
      sueItems: [],
      caseItems: [],
      items: [],
      statusCase: null,
      statusSue: null,
      OtherConfig,
    };
  },
  created() {
    this.loadDropdown();
    let arr = [
      {
        alwaysShow: false,
        name: "Import Excel File (initial)",
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        condition: () => {
          return true;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(StatusCase["New"]);
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "คลิกปุ่ม งดฟ้อง",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["New"].id);
          arr.push(StatusCase["รอสืบทายาท"].id);
          arr.push(StatusCase["พร้อมทำ Notice"].id);
          arr.push(StatusCase["ทำ Notice เสร็จแล้ว"].id);
          arr.push(StatusCase["รอวันฟ้องจากทนาย"].id);
          arr.push(StatusCase["เตรียมเอกสารฟ้อง"].id);
          arr.push(StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"].id);
          arr.push(StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }
          return false;
        },
        func: () => {},
      },
      {
        alwaysShow: true,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "แก้ไขที่อยู่",
        condition: () => {
          if (_.isNil(this.innerStatusCase)) {
            return false;
          }

          return true;
        },
        func: () => {
          if (this.innerStatusCase.id === StatusCase["New"].id) {
            this.innerStatusCase = this.getDropdownItem(
              StatusCase["พร้อมทำ Notice"]
            );
            this.innerStatusSue = null;
          }
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "คลิกปุ่ม ทำ Notice เสร็จแล้ว",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["พร้อมทำ Notice"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }
          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(
            StatusCase["ทำ Notice เสร็จแล้ว"]
          );
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "ส่ง line 1st ให้ทนายเข้ามาใส่วันฟ้อง",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["ทำ Notice เสร็จแล้ว"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }
          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(
            StatusCase["รอวันฟ้องจากทนาย"]
          );
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "คลิกปุ่ม ทำเอกสารฟ้องเสร็จแล้ว",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["เตรียมเอกสารฟ้อง"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(
            StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"]
          );
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: true,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "ส่งคำฟ้องให้ทนาย ส่ง line notification 2nd",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(
            StatusCase["ส่งคำฟ้องให้ทนายแล้ว"]
          );
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "[ทายาท] รอสืบทายาท",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["New"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(StatusCase["รอสืบทายาท"]);
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "[ทายาท] สืบแล้ว ไม่พบทายาท",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["รอสืบทายาท"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(StatusCase["คืนงาน"]);
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.ADMIN, UserRole.BACK_OFFICE],
        name: "[ทายาท] สืบแล้ว พบทายาท",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["รอสืบทายาท"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(
            StatusCase["พร้อมทำ Notice"]
          );
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.LAWYER],
        name: "[คดี] ทนายใส่วันฟ้องเข้ามา",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["รอวันฟ้องจากทนาย"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          this.innerStatusCase = this.getDropdownItem(
            StatusCase["เตรียมเอกสารฟ้อง"]
          );
          this.innerStatusSue = null;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.LAWYER],
        name: '[คดี] ทนายได้รับ line notification 2nd ว่าคำฟ้องเสร็จแล้ว, เลือก "ฟ้อง" ใส่หมายเลขคดีดำ',
        condition: () => {
          let arr = [];
          arr.push(StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          let result = fakeUpdateStatus.getNewStatus({
            option: StatusSue.waiting.option.fong,
            statusSueBeforePost: innerStatusSue,
          });
          this.innerStatusCase = result.statusCase;
          this.innerStatusSue = result.statusSue;
        },
      },
      {
        alwaysShow: false,
        permission: [UserRole.LAWYER],
        name: '[คดี] ทนายได้รับ line notification 2nd ว่าคำฟ้องเสร็จแล้ว, เลือก "เลื่อน"',
        condition: () => {
          let arr = [];
          arr.push(StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {},
      },
      {
        alwaysShow: false,
        permission: [UserRole.LAWYER],
        name: "[คดี] ถอนฟ้อง",
        condition: () => {
          let arr = [];
          arr.push(StatusCase["ระหว่างยื่นฟ้อง"].id);

          let statusCaseId = this.innerStatusCase
            ? this.innerStatusCase.id
            : null;
          if (arr.includes(statusCaseId)) {
            return true;
          }

          return false;
        },
        func: () => {
          let result = fakeUpdateStatus.getNewStatus({
            option: StatusSue.yuenFong.option.tonFong,
            statusSueBeforePost: innerStatusSue,
          });
          this.innerStatusCase = result.statusCase;
          this.innerStatusSue = result.statusSue;
        },
      },
    ];

    this.items.push(...arr);
  },
  methods: {
    emitStatus() {
      this.$emit("status-changed", {
        statusCase: this.statusCase,
        statusSue: this.statusSue,
      });
    },
    loadDropdown() {
      getStatusSue().then((res) => {
        this.sueItems = res.items;
      });
      getStatusCase().then((res) => {
        this.caseItems = res.items;
      });
    },
    getDropdownItem(obj) {
      return _.pick(obj, ["id", "text"]);
    },
  },
};
</script>